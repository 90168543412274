/**
* Template Name: Lumia - v4.9.1
* Template URL: https://bootstrapmade.com/lumia-bootstrap-business-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
@font-face {
    font-family: 'Luckiest Guy Regular';
    src: url('../LuckiestGuy-Regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

body {
    font-family: 'Mukta rev=1';
    font-size: 14px;
    color: #444444;
}

a {
    color: #3498db;
    text-decoration: none;
}

a:hover {
    color: #5faee3;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Raleway', sans-serif;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #000;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
}

.back-to-top i {
    font-size: 28px;
    color: white;
    background-color: #000;
    line-height: 0;
}

.back-to-top:hover {
    background: rgba(0, 0, 0, 0.39);
    color: #fff;
}

.back-to-top.active {
    visibility: visible;
    opacity: 1;
}
.swiper-wrapper {
    height: auto !important;
}
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    height: auto;
    transition: all 0.5s;
    z-index: 997;
    transition: all 0.5s;
    background: linear-gradient(180deg, rgb(255 255 255) 15%, rgb(255 255 255) 28%, #8cd1ff 258%);
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#header .logo h1 {
    font-size: 28px;
    margin: 0;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #384046;
}

#header .logo h1 a,
#header .logo h1 a:hover {
    color: #384046;
    text-decoration: none;
}

#header .logo img {
    max-height: 50%;
}
.header-scrolled {
    /*height: 168px !important;*/
}
.logo {
    /*margin-left: 2%;*/
    margin: 0 auto;
    width: 111px;
    text-align: center;
    /*padding-top: 10px;*/
    position: relative;
}
@media (max-width: 768px) {
    #header .logo img {
        max-height: 150px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
    #header {
        /*height: auto;*/
        padding-bottom: 20px;
    }
    .header-scrolled {
        /*height: 70px !important;*/
    }
    .logo {
        margin-bottom: 0 !important;
        /*margin-top: 9px;*/
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }
}

/*--------------------------------------------------------------
# Header Social Links
--------------------------------------------------------------*/
.header-social-links {
    margin: 0 0 0 30px;
}

.header-search-bar {
    display: block;
}

.header-search-bar-menu {
    display: none !important;
}

.header-social-links a {
    color: #0089f9;
    padding-left: 8px;
    display: inline-block;
    line-height: 0px;
    transition: 0.3s;
    padding-right: 18px;
}
.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none !important;
}
.header-social-links a i {
    line-height: 0;
}

.header-social-links a:hover {
    color: #3498db;
}

@media (max-width: 768px) {
    .header-social-links {
        margin: 0 15px 0 0;
    }
    .header-search-bar {
        display: none !important;
    }
    .header-search-bar-menu {
        display: flex !important;
    }
    .menu-header-info-two {
        margin-top: 0 !important;
    }
    .navbar-secondary {
        height: auto !important;
    }
}
.menu-header-info-two {
    margin-top: 30px;
}
.section-top-meniu {
    padding: 4px 0 4px 0px !important;
}
#topbar {
    /* background-color: #3498db!important; */
}
/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation
*/
.navbar-secondary {
    font-size: 13px;
    height: 40px;
    display: flex;
}

.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
}

.navbar li {
    position: relative;
}
.custom_ul {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
}
.navbar a,
.navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em;
    /* font-family: 'Poppins', sans-serif; */
    font-size: 18px;
    font-weight: 500;
    color: #4f5a62;
    white-space: nowrap;
    transition: 0.3s;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
}

.navbar a i,
.navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
    color: #3498db;
}

.navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 9px 15px 0px rgb(127 137 161 / 25%);
    -webkit-box-shadow: 0px 9px 15px 0px rgb(127 137 161 / 25%);
    transition: 0.3s;
   
}

.navbar .dropdown ul li {
    min-width: 200px;
}

.navbar .dropdown ul a {
    padding: 5px 20px;
    font-size: 14px;
    font-weight: 400;
    color: #21262a;
}

.navbar .dropdown ul a i {
    font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
    color: #3498db;
}

.navbar .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
}

@media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
        left: -90%;
    }

    .navbar .dropdown .dropdown:hover > ul {
        left: -100%;
    }
}

/**
* Mobile Navigation
*/
.secondary-nav {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
}
.nav-right {
    float: right;
}

.mobile-toggle-middle {
    width: 100%;
    display: flex;
    justify-content: center;
}

.mobile-nav-toggle {
    color: #384046;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
}

/*  sitilizarea adresei/nr teelfon*/

.container-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mobile-nav-toggle.bi-x {
    color: #fff;
    z-index: 9999;
}

@media (max-width: 991px) {
    .mobile-nav-toggle {
        display: block;
    }

    .container-mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 1em;
        margin-right: 1em;
    }

    .navbar ul {
        display: none;
    }

    .mobile-container {
        display: flex;
        padding-right: 20px;
    }

    .nav-right {
        display: flex;
        justify-content: space-between;
    }

    .mobile-toggle-middle {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}

@media (max-width: 767px) {
    .mobile-nav {
        width: 100%;
    }

    .container-mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 1em;
        margin-right: 1em;
        gap: 1em;
    }

    .nav-right {
        display: flex;
        justify-content: space-between;
    }

    .mobile-toggle-middle {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}

.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(33, 38, 42, 0.9);
    transition: 0.3s;
    z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
}

.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #384046;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
    color: #3498db;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
    margin: 15px;
}

.navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
    min-width: 200px;
}

.navbar-mobile .dropdown ul a {
    padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
    font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
    color: #3498db;
}

.navbar-mobile .dropdown > .dropdown-active {
    display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    height: 60vh;
    background: url('../img/hero-bg.jpg') center center;
    background-size: cover;
    position: relative;
    margin-top: 70px;
    padding: 0;
}

#hero:before {
    content: '';
    background: rgba(56, 64, 70, 0.7);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

#hero .container {
    z-index: 2;
}

#hero h1 {
    margin: 0 0 10px 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #fff;
}

#hero h1 span {
    border-bottom: 4px solid #3498db;
}

#hero h2 {
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 30px;
    font-size: 24px;
}

#hero .btn-get-started {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 2px;
    display: inline-block;
    padding: 12px 28px;
    border-radius: 4px;
    transition: ease-in-out 0.3s;
    color: #fff;
    background: #3498db;
    text-transform: uppercase;
}

#hero .btn-get-started:hover {
    background: #4ea5e0;
}

.hero-slider {
    padding-top: 7.4%!important;
}

@media (max-width: 992px) {
    #hero {
        height: calc(100vh - 70px);
    }
    .hero-slider {
        padding-top: calc(35vh - 70px) !important;
    }
}

@media (max-width: 768px) {
    #hero h1 {
        font-size: 30px;
        line-height: 36px;
    }

    #hero h2 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 30px;
    }
    section {
        padding: 70px 0 !important;
        padding-bottom: 62px;
    }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
    /*padding-bottom: 62px;*/
}

.section-bg {
    background-color: #f7fbfe;
}

.section-title {
    text-align: center;
    padding-bottom: 30px;
}

.section-title h2 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
}

.section-title h2::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
}

.section-title h2::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #3498db;
    bottom: 0;
    left: calc(50% - 20px);
}

.section-title p {
    margin-bottom: 0;
}

/*--------------------------------------------------------------
# What We Do
--------------------------------------------------------------*/
.what-we-do .icon-box {
    text-align: center;
    padding: 30px 20px;
    transition: all ease-in-out 0.3s;
    background: #fff;
}

.what-we-do .icon-box .icon {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    background: #eaf4fb;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: ease-in-out 0.3s;
}

.what-we-do .icon-box .icon i {
    color: #3498db;
    font-size: 28px;
}

.what-we-do .icon-box h4 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
}

.what-we-do .icon-box h4 a {
    color: #384046;
    transition: ease-in-out 0.3s;
}

.what-we-do .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.what-we-do .icon-box:hover {
    border-color: #fff;
    box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
}

.what-we-do .icon-box:hover h4 a,
.what-we-do .icon-box:hover .icon i {
    color: #3498db;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
    /* padding: 10px 0; */
    padding: 38px 0 103px;
}

.about h3 {
    font-weight: 600;
    font-size: 32px;
}

.about ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
}

.about ul li + li {
    margin-top: 10px;
}

.about ul li {
    position: relative;
    padding-left: 26px;
}

.about ul i {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 22px;
    color: #3498db;
}

.about .icon-boxes {
    padding-top: 10px;
}

.about .icon-boxes h4 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 5px;
}

.about .icon-boxes i {
    font-size: 48px;
    color: #3498db;
}

.about .icon-boxes p {
    font-size: 15px;
    color: #848484;
}
/*************************  */
.testimonial-padding {
    padding: 87px 0 10px;
}
.noutati-padding {
    padding: 87px 0 10px;
    margin: -0.5px 0 0 0;
}
.valori-padding {
    padding: 87px 0 10px;
    margin: -0.5px 0 0 0;
}
.contact-padding {
    padding: 87px 0 10px;
    margin: -0.5px 0 0 0;
}
@media (max-width: 768px) {
    .noutati-padding {
        margin: 0;
    }
    .valori-padding {
        padding: 87px 5px 10px;
        margin: 0;
    }
    .contact-padding {
        padding: 87px 5px 10px;
        margin: 0;
    }
}

/*--------------------------------------------------------------
# Skills
--------------------------------------------------------------*/
.skills .progress {
    height: 60px;
    display: block;
    background: none;
    border-radius: 0;
}

.skills .progress .skill {
    padding: 10px 0;
    margin: 0 0 6px 0;
    text-transform: uppercase;
    display: block;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: #384046;
}

.skills .progress .skill .val {
    float: right;
    font-style: normal;
}

.skills .progress-bar-wrap {
    background: #e8eaec;
}

.skills .progress-bar {
    width: 1px;
    height: 10px;
    transition: 0.9s;
    background-color: #5faee3;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
    background: white;
    padding: 30px 0 40px 0;
}

.counts .count-box {
    padding: 30px 30px 25px 30px;
    width: 100%;
    position: relative;
    text-align: center;
    background: #fff;
}

.counts .count-box i {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    background: #3498db;
    padding: 12px;
    color: #fff;
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
}

.counts .count-box span {
    font-size: 36px;
    display: block;
    font-weight: 600;
    color: #124364;
}

.counts .count-box p {
    padding: 0;
    margin: 0;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
    padding: 30px;
    border-radius: 6px;
    background: #fff;
    transition: ease-in-out 0.3s;
}

.services .icon-box i {
    float: left;
    color: #3498db;
    font-size: 40px;
    line-height: 0;
}

.services .icon-box h4 {
    margin-left: 70px;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
}

.services .icon-box h4 a {
    color: #384046;
    transition: 0.3s;
}

.services .icon-box p {
    margin-left: 70px;
    line-height: 24px;
    font-size: 14px;
}

.services .icon-box:hover {
    box-shadow: 0px 2px 22px rgba(0, 0, 0, 0.08);
}

.services .icon-box:hover h4 a {
    color: #3498db;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio {
    padding: 60px 0;
}

.portfolio #portfolio-flters {
    padding: 0;
    margin: 0 0 35px 0;
    list-style: none;
    text-align: center;
}

.portfolio #portfolio-flters li {
    cursor: pointer;
    margin: 0 15px 15px 0;
    display: inline-block;
    padding: 10px 20px;
    font-size: 12px;
    line-height: 20px;
    color: #444444;
    border-radius: 4px;
    text-transform: uppercase;
    background: #fff;
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
    background: #3498db;
    color: #fff;
}

.portfolio #portfolio-flters li:last-child {
    margin-right: 0;
}

.portfolio .portfolio-wrap {
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    transition: 0.3s;
}

.portfolio .portfolio-wrap:hover {
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.16);
}

.portfolio .portfolio-item {
    position: relative;
    height: 360px;
    overflow: hidden;
}

.portfolio .portfolio-item figure {
    background: #000;
    overflow: hidden;
    height: 240px;
    position: relative;
    border-radius: 4px 4px 0 0;
    margin: 0;
}

.portfolio .portfolio-item figure:hover img {
    opacity: 0.4;
    transition: 0.4s;
}

.portfolio .portfolio-item figure .link-preview,
.portfolio .portfolio-item figure .link-details {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    line-height: 0;
    text-align: center;
    width: 36px;
    height: 36px;
    background: #fff;
    border-radius: 50%;
    transition: all 0.2s linear;
    overflow: hidden;
    font-size: 20px;
}

.portfolio .portfolio-item figure .link-preview i,
.portfolio .portfolio-item figure .link-details i {
    color: #384046;
    line-height: 0;
}

.portfolio .portfolio-item figure .link-preview:hover,
.portfolio .portfolio-item figure .link-details:hover {
    background: #3498db;
}

.portfolio .portfolio-item figure .link-preview:hover i,
.portfolio .portfolio-item figure .link-details:hover i {
    color: #fff;
}

.portfolio .portfolio-item figure .link-preview {
    left: calc(50% - 38px);
    top: calc(50% - 18px);
}

.portfolio .portfolio-item figure .link-details {
    right: calc(50% - 38px);
    top: calc(50% - 18px);
}

.portfolio .portfolio-item figure:hover .link-preview {
    opacity: 1;
    left: calc(50% - 44px);
}

.portfolio .portfolio-item figure:hover .link-details {
    opacity: 1;
    right: calc(50% - 44px);
}

.portfolio .portfolio-item .portfolio-info {
    background: #fff;
    text-align: center;
    padding: 30px;
    height: 90px;
    border-radius: 0 0 3px 3px;
}

.portfolio .portfolio-item .portfolio-info h4 {
    font-size: 18px;
    line-height: 1px;
    font-weight: 700;
    margin-bottom: 18px;
    padding-bottom: 0;
}

.portfolio .portfolio-item .portfolio-info h4 a {
    color: #333;
}

.portfolio .portfolio-item .portfolio-info h4 a:hover {
    color: #3498db;
}

.portfolio .portfolio-item .portfolio-info p {
    padding: 0;
    margin: 0;
    color: #b8b8b8;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
    padding-top: 0;
}

.portfolio-details .portfolio-details-slider img {
    width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
}

.portfolio-details
    .portfolio-details-slider
    .swiper-pagination
    .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #3498db;
}

.portfolio-details
    .portfolio-details-slider
    .swiper-pagination
    .swiper-pagination-bullet-active {
    background-color: #3498db;
}

.portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(56, 64, 70, 0.08);
}

.portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
    margin-top: 10px;
}

.portfolio-details .portfolio-description {
    padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
    padding: 0;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
    overflow: hidden;
}

.testimonials .testimonial-item {
    /* box-sizing: content-box;
  padding: 30px 30px 0 30px;
  margin: 30px 15px;
  text-align: center;
  min-height: 350px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  background: #fff; */
    background: #fff;
    box-shadow: 0 2px 12px #3498db; 
    box-sizing: content-box;
    margin: 7px 5px;
    min-height: 350px;
    padding: 30px 30px 0;
    text-align: center;
    border-radius: 8px;
    background: rgb(52, 152, 219);
    background: -moz-linear-gradient(
        0deg,
        rgba(52, 152, 219, 0.196516106442577) 0%,
        rgba(52, 152, 219, 0.020045518207282953) 51%
    );
    background: -webkit-linear-gradient(
        0deg,
        rgba(52, 152, 219, 0.196516106442577) 0%,
        rgba(52, 152, 219, 0.020045518207282953) 51%
    );
    background: linear-gradient(
        0deg,
        rgba(52, 152, 219, 0.196516106442577) 0%,
        rgba(52, 152, 219, 0.020045518207282953) 51%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3498db",endColorstr="#3498db",GradientType=1);
}

.testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50%;
    border: 4px solid #fff;
    margin: 0 auto;
}

.testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #111;
}

.testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
    color: #e1f0fa;
    font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
}

.testimonials .testimonial-item p {
    font-style: italic;
    margin: 0 auto 15px auto;
}

.testimonials .swiper-pagination {
    margin-top: 20px;
    position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #3498db;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #3498db;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
    text-align: center;
    margin-bottom: 20px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    padding: 30px 20px;
    background: #fff;
}

.team .member img {
    max-width: 60%;
    border-radius: 50%;
    margin: 0 0 30px 0;
}

.team .member h4 {
    font-weight: 700;
    margin-bottom: 2px;
    font-size: 18px;
}

.team .member span {
    font-style: italic;
    display: block;
    font-size: 13px;
}

.team .member p {
    padding-top: 10px;
    font-size: 14px;
    font-style: italic;
    color: #aaaaaa;
}

.team .member .social {
    margin-top: 15px;
}

.team .member .social a {
    color: #919191;
    transition: 0.3s;
}

.team .member .social a:hover {
    color: #3498db;
}

.team .member .social i {
    font-size: 18px;
    margin: 0 2px;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-wrap {
    padding: 30px;
    background: #fff;
    border-radius: 4px;
}

.contact .info i {
    font-size: 20px;
    color: #3498db;
    float: left;
    width: 44px;
    height: 44px;
    background: #eaf4fb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
}

.contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #384046;
}

.contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: #65747f;
}

.contact .info:hover i {
    background: #3498db;
    color: #fff;
}

.contact .php-email-form {
    width: 100%;
    padding: 30px;
    background: #fff;
    border-radius: 4px;
}

.contact .php-email-form .form-group {
    padding-bottom: 8px;
}

.contact .php-email-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 13px;
}

.contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .error-message br + br {
    margin-top: 25px;
}

.contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
}

.contact .php-email-form .loading:before {
    content: '';
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
}

.contact .php-email-form input {
    height: 44px;
}

.contact .php-email-form textarea {
    padding: 10px 12px;
}

.contact .php-email-form button[type='submit'] {
    background: #3498db;
    border: 0;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
}

.contact .php-email-form button[type='submit']:hover {
    background: #5faee3;
}

@-webkit-keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
    padding: 180px 0;
    margin-top: 100px;
}

.breadcrumbs h2 {
    font-size: 26px;
    font-weight: 300;
}

.breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 15px;
}

.breadcrumbs ol li + li {
    padding-left: 10px;
}

.breadcrumbs ol li + li::before {
    display: inline-block;
    padding-right: 10px;
    color: #4f5a62;
    content: '/';
}

@media (max-width: 768px) {
    .breadcrumbs .d-flex {
        display: block !important;
    }

    .breadcrumbs ol {
        display: block;
    }

    .breadcrumbs ol li {
        display: inline-block;
    }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
    color: white;
    font-size: 14px;
    /*background: #f7fbfe;*/
    /* background: url('/frontend/assets/img/footer-bg.jpg'); */
    background-color: #373737 !important;
}

#footer .footer-top {
    padding: 60px 0 30px 0;
    /* background: #fff; */
    color: #bbb;
}

#footer .footer-top .footer-contact {
    margin-bottom: 30px;
}

#footer .footer-top .footer-contact h4 {
    font-size: 22px;
    margin: 0 0 30px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
}

#footer .footer-top .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: 'Raleway', sans-serif;
    color: #bbb;
}

#footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
}

#footer .footer-top .footer-links {
    margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
    align-items: center;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

#footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #fff;
    font-size: 18px;
    line-height: 1;
}

#footer .footer-top .footer-links ul li {
    display: flex;
    align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}

#footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
    color: rgb(255, 249, 249);
}

#footer .footer-newsletter {
    font-size: 15px;
}

#footer .footer-newsletter h4 {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
}

#footer .footer-newsletter form {
    background: #fff;
    position: relative;
    border-radius: 4px;
    text-align: left;
}

#footer .footer-newsletter form input[type='email'] {
    border: 0;
    /* padding: 4px 8px; */
    width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type='submit'] {
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px 2px 20px;
    background: #3498db;
    color: #fff;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter form input[type='submit']:hover {
    background: #57aae1;
}

#footer .credits {
    padding-left: 5px;
    color: #fff;
}

#footer .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #3498db;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

#footer .social-links a:hover {
    background: #5faee3;
    color: #fff;
    text-decoration: none;
}
.text-end {
    text-align: end;
}

.testimonial-item .icon-box .icon i {
    color: #3498db;
    font-size: 28px;
}

.testimonial-item .icon-box .icon {
    align-items: center;
    background: #eaf4fb;
    border-radius: 50px;
    display: flex;
    height: 64px;
    justify-content: center;
    margin: 0 auto 20px;
    transition: 0.3s ease-in-out;
    width: 64px;
}
.btn-primary {
    background-color: #3498db !important;
    border-color: #3498db !important;
}

.ht-bottom-footer {
    background: rgba(0, 0, 0, 0.3);
}

/*
/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
    color: #444444;
    text-align: center;
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
    padding: 20px 0 30px 0;
    background: #fff;
}

.contact .info-box i {
    font-size: 32px;
    color: #5846f9;
    border-radius: 50%;
    padding: 8px;
}

.contact .info-box h3 {
    font-size: 20px;
    color: #2c4964;
    font-weight: 700;
    margin: 10px 0;
}

.contact .info-box p {
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.contact .php-email-form {
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
    padding: 30px;
    background: #fff;
}

.contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .error-message br + br {
    margin-top: 25px;
}

.contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
}

.contact .php-email-form .loading:before {
    content: '';
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
    border-radius: 5px;
    box-shadow: none;
    font-size: 14px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
    border-color: #5846f9;
}

.contact .php-email-form input {
    padding: 10px 15px;
}

.contact .php-email-form textarea {
    padding: 12px 15px;
}

.contact .php-email-form button[type='submit'] {
    background: #5846f9;
    border: 0;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
    border-radius: 5px;
}

.contact .php-email-form button[type='submit']:hover {
    background: #7b27d8;
}

@-webkit-keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
# Posts
--------------------------------------------------------------*/
.post-entry-1 {
    margin-bottom: 30px;
}

.post-entry-1 img {
    margin-bottom: 30px;
}

.post-entry-1 h2 {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    font-weight: 500;
}

.post-entry-1 h2 a {
    color: var(--color-black);
}

.post-entry-1.lg h2 {
    font-size: 40px;
    line-height: 1;
}

.post-meta {
    font-size: 11px;
    letter-spacing: 0.07rem;
    text-transform: uppercase;
    font-weight: 600;
    font-family: var(--font-secondary);
    color: rgba(var(--color-black-rgb), 0.4);
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .custom-border {
        border: none !important;
    }
}

.author .photo {
    margin-right: 10px;
}

.author .photo img {
    width: 40px;
    border-radius: 50%;
    margin-bottom: 0;
}

.author .name h3 {
    margin: 0;
    padding: 0;
    font-size: 15px;
    font-family: var(--font-secondary);
}

.trending {
    border: 1px solid #dee2e6;
}

.trending > h3 {
    color: var(--color-black);
    padding: 20px;
    border-bottom: 1px solid #dee2e6;
}

.trending .trending-post {
    padding: 0;
    margin: 0;
}

.trending .trending-post li {
    padding: 0;
    margin: 0;
    list-style: none;
    display: block;
}

.trending .trending-post li a {
    display: block;
    padding: 20px;
    border-bottom: 1px solid #dee2e6;
    position: relative;
    overflow: hidden;
}

.trending .trending-post li a .number {
    position: absolute;
    z-index: -1;
    font-size: 5rem;
    left: -10px;
    top: -20px;
    font-weight: 700;
    color: rgba(var(--color-black-rgb), 0.05);
}

.trending .trending-post li a h3 {
    font-size: 18px;
    color: rgba(var(--color-black-rgb), 0.9);
    margin-left: 18px;
}

.trending .trending-post li a .author {
    color: rgba(var(--color-black-rgb), 0.7);
    font-weight: 500;
}

.trending .trending-post li a:hover h3 {
    color: rgba(var(--color-black-rgb), 1);
}

.trending .trending-post li:last-child a {
    border-bottom: none;
}

.post-entry-2 {
    margin-bottom: 30px;
}

.post-entry-2 .post-meta {
    font-size: 11px;
    letter-spacing: 0.07rem;
    text-transform: uppercase;
    font-weight: 600;
    font-family: var(--font-secondary);
    color: rgba(var(--color-black-rgb), 0.4);
    margin-bottom: 10px;
}

.post-entry-2 .author {
    color: rgba(var(--color-black-rgb), 0.7);
    font-weight: 500;
    margin-bottom: 20px;
    display: block;
}

.post-entry-2 .thumbnail {
    flex: 0 0 65%;
}

@media (max-width: 960px) {
    .post-entry-2 .thumbnail {
        flex: 0 0 100%;
        margin-bottom: 20px;
    }
}

.post-entry-2.half .thumbnail {
    flex: 0 0 50%;
}

@media (max-width: 768px) {
    .post-entry-2.half .thumbnail {
        flex: 0 0 100%;
        margin-bottom: 20px;
    }
}

.post-entry-2.small-img .thumbnail {
    flex: 0 0 30%;
}

@media (max-width: 768px) {
    .post-entry-2.small-img .thumbnail {
        flex: 0 0 100%;
        margin-bottom: 20px;
    }
}

.img-bg {
    height: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: center center;
}

@media (max-width: 768px) {
    .img-bg {
        height: 400px;
    }
}

.img-bg:before {
    position: absolute;
    content: '';
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.5;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    top: 0;
}

.img-bg .img-bg-inner {
    position: relative;
    z-index: 2;
    /* max-width: 700px; */
    position: relative;
    /* padding-left: 100px; */
    /* padding-right: 100px; */
    /* margin-bottom: 193px; */
    color: white;
}

@media (max-width: 768px) {
    .img-bg .img-bg-inner {
        padding-left: 30px;
        padding-right: 30px;
        /* margin-bottom: 50px; */
        text-align: center;
    }
}

.img-bg .img-bg-inner h2,
.img-bg .img-bg-inner p {
    color: var(--color-white);
}

@media (max-width: 500px) {
    .img-bg .img-bg-inner p {
        /* display: none; */
        padding: 0;
    }
}

.custom-swiper-button-next,
.custom-swiper-button-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    color: white;
    padding-bottom: 100%;
    padding-top: 100%;
}
.custom-swiper-button-prev:hover {
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(
        270deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.6082808123249299) 80%,
        rgba(0, 0, 0, 0.6110819327731092) 88%,
        rgba(19, 19, 19, 0.742734593837535) 98%
    );
    background: -webkit-linear-gradient(
        270deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.6082808123249299) 80%,
        rgba(0, 0, 0, 0.6110819327731092) 88%,
        rgba(19, 19, 19, 0.742734593837535) 98%
    );
    background: linear-gradient(
        270deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.6082808123249299) 80%,
        rgba(0, 0, 0, 0.6110819327731092) 88%,
        rgba(19, 19, 19, 0.742734593837535) 98%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#131313",GradientType=1);
    width: 20%;
    text-align: left;
}
.custom-swiper-button-next:hover {
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.6082808123249299) 80%,
        rgba(0, 0, 0, 0.6110819327731092) 88%,
        rgba(19, 19, 19, 0.742734593837535) 98%
    );
    background: -webkit-linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.6082808123249299) 80%,
        rgba(0, 0, 0, 0.6110819327731092) 88%,
        rgba(19, 19, 19, 0.742734593837535) 98%
    );
    background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.6082808123249299) 80%,
        rgba(0, 0, 0, 0.6110819327731092) 88%,
        rgba(19, 19, 19, 0.742734593837535) 98%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#131313",GradientType=1);
    width: 20%;
    text-align: right;
}
@media (max-width: 768px) {
    .custom-swiper-button-next,
    .custom-swiper-button-prev {
        display: none;
    }
}

.custom-swiper-button-next span,
.custom-swiper-button-prev span {
    font-size: 20px;
    color: white;
    font-weight: bold;
    transition: 0.3s all ease;
}

.custom-swiper-button-next:hover span,
.custom-swiper-button-next:focus span,
.custom-swiper-button-prev:hover span,
.custom-swiper-button-prev:focus span {
    color: rgba(var(--color-white-rgb), 1);
}

.custom-swiper-button-next {
    right: 0px;
    padding-right: 18px;
}

.custom-swiper-button-prev {
    left: 0px;
    padding-left: 18px;
}

.swiper-pagination .swiper-pagination-bullet {
    background-color: rgba(var(--color-white-rgb), 0.8);
}

.swiper-pagination .swiper-pagination-bullet-active {
    background-color: rgba(var(--color-white-rgb), 1);
}

.more {
    font-family: var(--font-secondary);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    position: relative;
    display: inline-block;
    padding-bottom: 5px;
}

.more:before {
    content: '';
    position: absolute;
    height: 2px;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--color-black);
}

.post-content {
    padding-left: 10%;
    padding-right: 10%;
}

@media (max-width: 768px) {
    .post-content {
        padding-left: 15px;
        padding-right: 15px;
    }
    .single-post img {
        height: 100%;
        width: 100%;
    }
}

.post-content .firstcharacter {
    float: left;
    font-family: Georgia;
    font-size: 75px;
    line-height: 60px;
    padding-top: 4px;
    padding-right: 8px;
    padding-left: 3px;
}

.post-content figure {
    position: relative;
    left: 50%;
    min-width: 990px;
    transform: translateX(-50%);
}

@media (max-width: 1255px) {
    .post-content figure {
        min-width: auto;
        left: auto !important;
        transform: none;
    }
}

.post-content figure figcaption {
    font-family: var(--font-secondary);
    font-size: 14px;
    padding: 10px 0 0 0;
}

.aside-title,
.category-title {
    border-bottom: 1px solid #4f5a62;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.1rem;
    margin-bottom: 16px;
    text-transform: uppercase;
    height: 27px;
}

.category-title {
    border-bottom: none;
}

.custom-pagination a {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    font-family: var(--font-secondary);
    margin: 5px;
    transition: 0.3s all ease;
}

.custom-pagination a.active {
    background: var(--color-black);
    color: var(--color-white);
}

.custom-pagination a.active:hover {
    background: rgba(var(--color-black-rgb), 0.9);
}

.custom-pagination a:hover {
    background: rgba(var(--color-black-rgb), 0.1);
}

.custom-pagination a.prev,
.custom-pagination a.next {
    width: auto !important;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
}

.custom-pagination a.prev:hover,
.custom-pagination a.next:hover {
    background: rgba(var(--color-black-rgb), 0.1);
}

/* custom tab nav on sidebar */
.aside-block {
    margin-bottom: 30px;
}

.aside-block .custom-tab-nav .nav-item {
    display: inline-block;
}

.aside-block .custom-tab-nav .nav-item button {
    color: var(--color-black);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 2px;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    margin-right: 20px;
}

.aside-block .custom-tab-nav .nav-item button.active {
    background-color: var(--color-black) !important;
    background-color: transparent !important;
    color: var(--color-black);
    border-bottom: 2px solid var(--color-black);
}

.link-video {
    position: relative;
}

.link-video span {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    background-color: rgba(var(--color-black-rgb), 0.2);
    color: var(--color-white);
}

.aside-links li {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(var(--color-black-rgb), 0.1);
}

.aside-links li a {
    display: block;
    color: rgba(var(--color-black-rgb), 0.7);
}

.aside-links li a:hover,
.aside-links li a:focus {
    color: #3498db !important;
}

.aside-tags li {
    display: inline-block;
}

.aside-tags li a {
    display: inline-block;
    color: rgba(var(--color-black-rgb), 0.7);
    padding: 7px 10px;
    border: 1px solid rgba(var(--color-black-rgb), 0.1);
    margin-bottom: 3px;
    transition: 0.3s all ease;
}

.aside-tags li a:hover,
.aside-tags li a:focus {
    color: rgba(var(--color-black-rgb), 1);
    border: 1px solid rgba(var(--color-black-rgb), 0.5);
}

/*** end posts * */

/*** custom pagination ***/
.custom-pagination a {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    font-family: var(--font-secondary);
    margin: 5px;
    transition: 0.3s all ease;
    color: #4f5a62;
    font-weight: bold;
}

.custom-pagination a.active {
    background: #3498db;
    color: var(--color-white);
    color: white;
}

.custom-pagination a.active:hover {
    background: #3498db;
    color: white;
}

.custom-pagination a:hover {
    background: #3498db;
    color: white;
}

.custom-pagination a.prev,
.custom-pagination a.next {
    width: auto !important;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
}

.custom-pagination a.prev:hover,
.custom-pagination a.next:hover {
    background: #3498db;
}

/***MN custom css img **/
.img-fluid-custom {
    max-height: 100% !important;
    height: 200px !important;
}
.img-fluid {
    border-radius: 15px !important;
}
.img-post {
    max-width: 94% !important;
}

.img-section {
    /*background-image: url("about.jpg");*/
}

/** rainbbow color csss **/
.rainbow {
    background-image: linear-gradient(
        to right,
        #ff0000 0%,
        #ff7f00 14%,
        #ffff00 28%,
        #00ff00 42%,
        #0000ff 56%,
        #4b0082 70%,
        #9400d3 84%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.rainbow {
    background: linear-gradient(
        90deg,
        #ff0000,
        #ff7f00,
        #ffff00,
        #00ff00,
        #0000ff,
        #4b0082,
        #9400d3
    );
    background-size: 800% 800%;
    -webkit-animation: rainbow 15s ease infinite;
    -z-animation: rainbow 15s ease infinite;
    -o-animation: rainbow 15s ease infinite;
    animation: rainbow 15s ease infinite;
}

.rainbow-violet {
    color: #9400d3 !important;
}
.rainbow-indigo {
    color: #4b0082 !important;
}
.rainbow-blue {
    color: #0000ff !important;
}
.rainbow-green {
    color: #00ff00 !important;
}
.rainbow-yellow {
    color: #b9b90e !important;
}
.rainbow-orange {
    color: #ff7f00 !important;
}
.rainbow-red {
    color: #ff0000 !important;
}

.rainbow-violet-background {
    background-color: #9400d39d !important;
}
.rainbow-indigo-background {
    background-color: #4c0082a2 !important;
}
.rainbow-blue-background {
    background-color: #0000ffaf !important;
}
.rainbow-green-background {
    background-color: #00ff009d !important;
}
.rainbow-yellow-background {
    background-color: #b9b90e9c !important;
}
.rainbow-orange-background {
    background-color: #ff80009f !important;
}
.rainbow-red-background {
    background-color: #ff000094 !important;
}

.section-background-two {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
@media (max-width: 768px) {
    .section-background-two {
        background-position: 100%;
    }
    /*** slider text title ***/
    .slider-text-title-primer {
        font-size: 2rem !important;
    }
    .margin-custom-slider-text {
        margin-bottom: 2rem !important;
    }
}
.section-background {
    background-position: bottom;
    background-repeat: no-repeat;
    /* background-size: cover; */
    position: relative;
}

/*** slider text title ***/
.slider-text-title-primer {
    /* font-family: 'Luckiest Guy Regular' !important; */
    font-size: 2.5rem;
    color: #fffafa !important;
    padding: 0px;
    /* line-height: 50%; */
}
.margin-custom-slider-text {
    margin-bottom: 7.5rem;
}
/*** slider text title ***/
.sub-title-primer {
    font-family: 'Luckiest Guy Regular' !important;
    font-size: 2rem !important;
    color: black;
    bottom: 0.99em !important;
}

/*** tables ***/

table {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;

    border-collapse: separate;
    border-spacing: 0;
    border-color: aliceblue;
}

tbody tr:nth-child(odd) {
    background-color: #ece9e9;
}

th,
td {
    /* cell */
    padding: 0.75rem;
    font-size: 0.9375rem;
}

th {
    /* header cell */
    font-weight: 700;
    text-align: left;
    color: #272838;
    border-bottom: 2px solid #eb9486;

    position: sticky;
    top: 0;
    background-color: #f9f8f8;
}

td {
    /* body cell */
    color: #7e7f9a;
}
#canvases canvas {
    margin: 20px auto;
    display: block;
}

@media (min-width: 1400px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1540px;
    }
}
.copy_clikboard {
    cursor: pointer;
    border: 2px;
    background: black;
    border-radius: 7px;
    padding-left: 13px;
    padding-right: 13px;
    color: white;
    padding-bottom: 2px;
}
